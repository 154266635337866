import { Head as InertiaHead } from '@inertiajs/react';

interface HeadProps {
    title?: string;
    description?: string;
    keywords?: string;
    ogImage?: string;
    ogUrl?: string;
}

export default function Head({
    title = 'PolkaIdentity | Decentralized Identity in Polkadot',
    description = 'Manage your decentralized identity on Polkadot. Verify your social media accounts, email, and website ownership in a decentralized way.',
    keywords = 'polkadot, identity, blockchain, decentralized, web3, cryptocurrency',
    ogImage = '/images/og-image.png',
    ogUrl = 'https://polkaidentity.com'
}: HeadProps) {
    return (
        <InertiaHead>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            
            {/* Open Graph / Social Media */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content={ogUrl} />
            
            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={ogImage} />

            {/* Other Important Meta Tags */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="robots" content="index, follow" />
            
            {/* Favicon */}
            <link rel="icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        </InertiaHead>
    );
}
